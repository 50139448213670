@tailwind base;
@tailwind components;
@tailwind utilities;

/* Google font Poppins */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;800&family=VT323&display=swap');

/* Profile dropdown */
.dropdown:focus-within .dropdown-menu {
  opacity:1;
  transform: translate(0) scale(1);
  visibility: visible;
}

/* Filter dropdown */
.dropdown_filter:focus-within .dropdown-menu_filter {
  opacity:1;
  transform: translate(0) scale(1);
  visibility: visible;
}


.background {
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: -10;
  background-color:hsla(0,0%,0%,1);
background-image:
radial-gradient(at 0% 26%, hsla(289,77%,22%,0.77) 0px, transparent 50%),
radial-gradient(at 0% 100%, hsla(240,79%,30%,0.55) 0px, transparent 50%),
radial-gradient(at 98% 48%, hsla(248,79%,30%,0.88) 0px, transparent 50%),
radial-gradient(at 39% 56%, hsla(183,79%,30%,0.71) 0px, transparent 50%),
radial-gradient(at 75% 89%, hsla(214,73%,28%,0.73) 0px, transparent 50%)
    /* url('./assets/nnnoise2.svg') */;
}

.background-error {
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: -10;
  background-color:hsla(0,0%,0%,1);
background-image:
radial-gradient(at 0% 0%, hsla(289,77%,22%,0.37) 0px, transparent 20%),
radial-gradient(at 0% 100%, hsla(240,79%,30%,0.55) 0px, transparent 50%),
radial-gradient(at 50% 50%, hsla(248,79%,30%,0.38) 0px, transparent 80%),
radial-gradient(at 100% 100%, hsla(183,79%,30%,0.31) 0px, transparent 40%),
radial-gradient(at 100% 0%, hsla(249, 49%, 46%, 0.31) 0px, transparent 50%)
    /* url('./assets/nnnoise2.svg') */;
}


.background-publish {
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: -10;
  background-color:hsla(0,0%,0%,1);
background-image:
radial-gradient(at 0% 0%, hsla(289,77%,22%,0.37) 0px, transparent 20%),
radial-gradient(at 0% 100%, hsla(240,79%,30%,0.55) 0px, transparent 50%),
radial-gradient(at 50% 50%, hsla(248,79%,30%,0.38) 0px, transparent 80%),
radial-gradient(at 100% 100%, hsla(183,79%,30%,0.31) 0px, transparent 40%),
radial-gradient(at 100% 0%, hsla(249, 49%, 46%, 0.31) 0px, transparent 50%)
    /* url('./assets/nnnoise2.svg') */;
}

.background-home {
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: -10;
  background-color:hsla(0,0%,0%,1);
background-image:
radial-gradient(at 0% 0%, hsla(289,77%,22%,0.57) 0px, transparent 30%),
radial-gradient(at 0% 100%, hsla(240,79%,30%,0.55) 0px, transparent 50%),
radial-gradient(at 25% 50%, hsla(288, 79%, 30%, 0.48) 0px, transparent 70%),
radial-gradient(at 100% 100%, hsla(183,79%,30%,0.31) 0px, transparent 40%),
radial-gradient(at 100% 0%, hsla(208, 49%, 46%, 0.41) 0px, transparent 20%)
    /* url('./assets/nnnoise2.svg') */;
}

.background-login {
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: -10;
  background-color:hsla(0,0%,0%,1);
background-image:
radial-gradient(at 25% 0%, hsla(273, 77%, 22%, 0.37) 0px, transparent 30%),
radial-gradient(at 100% 0%, hsla(240,79%,30%,0.55) 0px, transparent 50%),
radial-gradient(at 25% 100%, hsla(255, 79%, 30%, 0.48) 0px, transparent 70%),
radial-gradient(at 100% 100%, hsla(183,79%,30%,0.51) 0px, transparent 40%)
    /* url('./assets/nnnoise2.svg') */;
}

.background-profile {
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: -10;
  background-color:hsla(0,0%,0%,1);
background-image:
radial-gradient(at 0% 0%, hsla(289,77%,22%,0.37) 0px, transparent 20%),
radial-gradient(at 0% 100%, hsla(240,79%,30%,0.55) 0px, transparent 50%),
radial-gradient(at 50% 50%, hsla(248,79%,30%,0.38) 0px, transparent 80%),
radial-gradient(at 100% 100%, hsla(183,79%,30%,0.31) 0px, transparent 40%),
radial-gradient(at 100% 0%, hsla(249, 49%, 46%, 0.31) 0px, transparent 50%)
    /* url('./assets/nnnoise2.svg') */;
}

.background-loading {
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: -10;
  background-color:hsla(0,0%,0%,1);
background-image:
radial-gradient(at 0% 0%, hsla(289,77%,22%,0.37) 0px, transparent 20%),
radial-gradient(at 0% 100%, hsla(240,79%,30%,0.55) 0px, transparent 50%),
radial-gradient(at 50% 50%, hsla(248,79%,30%,0.38) 0px, transparent 80%),
radial-gradient(at 100% 100%, hsla(183,79%,30%,0.31) 0px, transparent 40%),
radial-gradient(at 100% 0%, hsla(249, 49%, 46%, 0.31) 0px, transparent 50%)
    /* url('./assets/nnnoise2.svg') */;
}